import Vue from 'vue'
import VueRouter from 'vue-router'

/* Layout */
import Layout from '@/layout';
import LoginLayout from '@/layout/login';


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: 'Home', icon: 'home', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: 'Home', icon: 'home', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'vendors',
    children: [
      {
        path: 'vendors',
        component: () => import('@/views/vendor/index'),
        name: 'Vendors',
        meta: { title: 'Vendors', icon: 'Vendors', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'vendor-services',
    children: [
      {
        path: 'vendor-services/:id',
        component: () => import('@/views/checkout/services'),
        name: 'Vendors',
        meta: { title: 'Vendor Services', icon: 'Vendor Services', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'service-slots',
    children: [
      {
        path: 'service-slots/:vendor_location_id',
        component: () => import('@/views/checkout/slots'),
        name: 'Slots',
        meta: { title: 'Service slots', icon: 'Service slots', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'confirm-payment',
    children: [
      {
        path: 'confirm-payment',
        component: () => import('@/views/checkout/payment-confirm'),
        name: 'Confirm Payment',
        meta: { title: 'Confirm Payment', icon: 'Confirm Payment', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'success-payment',
    children: [
      {
        path: 'success-payment',
        component: () => import('@/views/checkout/payment-success'),
        name: 'Confirm Payment',
        meta: { title: 'Success Payment', icon: 'Success Payment', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'contact',
    children: [
      {
        path: 'contact',
        component: () => import('@/views/contact/index'),
        name: 'Contact',
        meta: { title: 'Contact', icon: 'Contact', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'about',
    children: [
      {
        path: 'about',
        component: () => import('@/views/about/index'),
        name: 'About',
        meta: { title: 'About', icon: 'About', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: LoginLayout,
    redirect: 'login',
    children: [
      {
        path: 'login',
        component: () => import('@/views/auth/login'),
        name: 'Login',
        meta: { title: 'Login', icon: 'Login', noCache: false },
      },
    ],
  },

  {
    path: '',
    component: Layout,
    redirect: 'register',
    children: [
      {
        path: 'register',
        component: () => import('@/views/auth/register'),
        name: 'Register',
        meta: { title: 'Register', icon: 'Register', noCache: false },
      },
    ],
  },
]

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.MIX_LARAVUE_PATH,
  routes: routes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

