import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './module/auth';
import service from './module/service';
import common from './module/common';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    service,
    common
  },
  plugins: [createPersistedState()]
});
