/**
|--------------------------------------------------------------------------
| Vue Component : Header
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:15 PM)
|
*/
<template>
  <header class="page-header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 logo-bar">
          <router-link to="/home"><img src="images/logo.svg" alt=""></router-link>
          <div class="actions">
            <div class="language-switcher">
              <ul>
                <li class="active">
                  <a href="#">
                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M7.56097 9.19119H2.68439L1.78607 11.6776H0.246094L4.28853 0.560913H5.97288L9.99927 11.6776H8.45929L7.56097 9.19119ZM7.1439 8.00413L5.12268 2.35755L3.10146 8.00413H7.1439Z"
                          fill="#3B97CB" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8 10.7244L6.00395 12C4.21564 11.9185 2.93754 11.7352 2.16963 11.45C0.723207 10.9153 0 9.91216 0 8.44048C0 7.55442 0.205128 6.76257 0.615385 6.06493C0.799473 5.74921 1.18606 5.26289 1.77515 4.60598C1.28073 4.36665 0.930966 4.15277 0.725839 3.96435C0.294543 3.57225 0.0788953 3.0783 0.0788957 2.4825C0.0788953 1.94781 0.315581 1.41821 0.788955 0.893699C1.32544 0.297906 1.97238 7.12493e-06 2.72978 0C3.28205 7.12493e-06 3.96844 0.308091 4.78896 0.924252C2.07495 1.06175 0.717948 1.46404 0.717949 2.13113C0.717948 2.44176 1.02038 2.73711 1.62525 3.01719C2.14595 3.25653 2.72452 3.42203 3.36095 3.51369C4.4497 2.94335 5.54109 2.53088 6.63511 2.27626L6.30375 3.46785C4.547 4.12476 3.35305 4.64672 2.72189 5.03374C1.36489 5.85869 0.68639 6.8135 0.686391 7.89815C0.68639 9.75175 2.62985 10.6785 6.51677 10.6785C7.17422 10.6785 7.66863 10.6709 8 10.6556V10.7244Z"
                          fill="#626F76" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <span  :class="'burger '+clicked" @click="onClickButton">
              <span></span>
          </span>
          </div>
        </div>
        <div class="col-md-9 text-right menu-bar">
          <nav :class="'navigation '+active" >
            <ul>
              <li><router-link to="/home" class="home-link">
                <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M13.0903 19.7851V14.6032C13.0903 14.3742 12.9993 14.1545 12.8373 13.9925C12.6754 13.8306 12.4557 13.7396 12.2266 13.7396H8.77209C8.54304 13.7396 8.32338 13.8306 8.16141 13.9925C7.99945 14.1545 7.90846 14.3742 7.90846 14.6032V19.7851C7.90846 20.0142 7.81748 20.2338 7.65554 20.3958C7.4936 20.5578 7.27396 20.6488 7.04493 20.6488L1.86374 20.6495C1.75032 20.6495 1.63801 20.6272 1.53321 20.5838C1.42842 20.5404 1.3332 20.4767 1.25299 20.3966C1.17278 20.3164 1.10916 20.2211 1.06575 20.1164C1.02234 20.0116 1 19.8993 1 19.7858V9.80431C1 9.68399 1.02514 9.56499 1.07381 9.45496C1.12249 9.34492 1.19361 9.24626 1.28264 9.16532L9.91841 1.31327C10.0774 1.16873 10.2845 1.08863 10.4994 1.08862C10.7142 1.08862 10.9214 1.1687 11.0804 1.31323L19.7173 9.16532C19.8064 9.24626 19.8775 9.34492 19.9262 9.45496C19.9749 9.56501 20 9.68401 20 9.80434V19.7858C20 19.8993 19.9777 20.0116 19.9343 20.1164C19.8908 20.2211 19.8272 20.3164 19.747 20.3966C19.6668 20.4768 19.5716 20.5404 19.4668 20.5838C19.362 20.6272 19.2497 20.6495 19.1363 20.6495L13.9538 20.6488C13.7248 20.6488 13.5051 20.5578 13.3432 20.3958C13.1812 20.2338 13.0903 20.0142 13.0903 19.7851V19.7851Z"
                      stroke="#3B97CB" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                </svg>

              </router-link></li>

              <li> <router-link to="/about">{{ $t('route.about') }}</router-link></li>
              <li> <router-link to="/vendors">{{ $t('route.vendors') }}</router-link></li>
              <li><router-link to="/contact">{{ $t('route.contact') }}</router-link></li>
              <li>
                <a href="#"  v-if="isAuthenticated"  class="login-botton" @click.prevent="logout">Logout</a>
                <router-link v-else class="login-botton" :to="'/login?redirect='+$router.currentRoute.path">{{ $t('route.login') }}</router-link>
              </li>

            </ul>

          </nav>
          <div class="language-switcher">
            <ul>
              <li>
                <a href="#" class="active">
                  <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.56097 9.19119H2.68439L1.78607 11.6776H0.246094L4.28853 0.560913H5.97288L9.99927 11.6776H8.45929L7.56097 9.19119ZM7.1439 8.00413L5.12268 2.35755L3.10146 8.00413H7.1439Z"
                        fill="#3B97CB" />
                  </svg>

                </a>
              </li>
              <li>
                <a href="#">
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8 10.7244L6.00395 12C4.21564 11.9185 2.93754 11.7352 2.16963 11.45C0.723207 10.9153 0 9.91216 0 8.44048C0 7.55442 0.205128 6.76257 0.615385 6.06493C0.799473 5.74921 1.18606 5.26289 1.77515 4.60598C1.28073 4.36665 0.930966 4.15277 0.725839 3.96435C0.294543 3.57225 0.0788953 3.0783 0.0788957 2.4825C0.0788953 1.94781 0.315581 1.41821 0.788955 0.893699C1.32544 0.297906 1.97238 7.12493e-06 2.72978 0C3.28205 7.12493e-06 3.96844 0.308091 4.78896 0.924252C2.07495 1.06175 0.717948 1.46404 0.717949 2.13113C0.717948 2.44176 1.02038 2.73711 1.62525 3.01719C2.14595 3.25653 2.72452 3.42203 3.36095 3.51369C4.4497 2.94335 5.54109 2.53088 6.63511 2.27626L6.30375 3.46785C4.547 4.12476 3.35305 4.64672 2.72189 5.03374C1.36489 5.85869 0.68639 6.8135 0.686391 7.89815C0.68639 9.75175 2.62985 10.6785 6.51677 10.6785C7.17422 10.6785 7.66863 10.6709 8 10.6556V10.7244Z"
                        fill="#626F76" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>


<script>
import {mapActions} from "vuex";
import {Message} from "element-ui";

export default {
  name: "HeaderView",
  data() {
    return {
      clicked:'',
      active:'',
    }
  },
  computed : {
    isAuthenticated : function(){ return this.$store.getters.isAuthenticated},
  },

  methods:{
    ...mapActions(["LogOut"]),
    onClickButton(){
      this.clicked = this.clicked == '' ? 'clicked' : '';
      this.active = this.active == '' ? 'active' : '';
    },
    async logout() {
      await this.$store.dispatch('LogOut');
    }
  }
}
</script>
<style scoped>

</style>